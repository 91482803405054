.mat-slide-toggle.mat-checked:not(.mat-disabled)
  .mat-slide-toggle-thumb:before {
  content: "\f00c" !important;
  font: normal normal normal 14px/1 FontAwesome !important;
  padding: 3px !important;
}

mat-slide-toggle {
  label.mdc-label {
    padding-left: 8px;
  }

  button.mdc-switch {
    height: 20px;
    width: 36px;
    border-radius: 20px;

    svg {
      display: none;
    }

    &.mdc-switch--unselected {
      background-color: #c2ccd6;
    }

    &.mdc-switch--selected {
      background-color: var(--color-primary-blue-universe-700);
    }

    .mdc-switch__handle {
      .mdc-switch__shadow {
        display: none;
      }
      &:after,
      &:before {
        display: none;
      }
    }

    .mdc-switch__ripple {
      background-color: white;
      height: 16px;
      width: 16px;
      border-radius: 16px;
    }
  }

  .mdc-switch__track::after,
  .mdc-switch__track::before {
    display: none;
  }

  .mdc-switch__icons {
    display: none;
  }
}
