@import "~@angular/material/prebuilt-themes/pink-bluegrey.css";

@import "./checkbox.scss";
@import "./button.scss";
@import "./input.scss";
@import "./slide-toggle.scss";
@import "./mat-divider.scss";


:root {
  --color-primary-blue-universe-50: #E7E7FE
  --color-primary-blue-universe-100: #CFCEFD;
  --color-primary-blue-universe-200: #9F9DFB;
  --color-primary-blue-universe-300: #6E6CF9;
  --color-primary-blue-universe-400: #3E3BF7;
  --color-primary-blue-universe-500: #0E0AF5;
  --color-primary-blue-universe-600: #0B08C4;
  --color-primary-blue-universe-700: #08068D;
  --color-primary-blue-universe-800: #060462;
  --color-primary-blue-universe-900: #030231;

  --color-primary-yellow-sun-50: #FFF4E6;
  --color-primary-yellow-sun-100: #FFE9CC;
  --color-primary-yellow-sun-200: #FFD399;
  --color-primary-yellow-sun-300: #FFC880;
  --color-primary-yellow-sun-400: #FFA733;
  --color-primary-yellow-sun-500: #FF9E1B;
  --color-primary-yellow-sun-600: #CC7400;
  --color-primary-yellow-sun-700: #995700;
  --color-primary-yellow-sun-800: #663A00;
  --color-primary-yellow-sun-900: #331D00;

  --color-primary-green-flora-50: #E9FBF2;
  --color-primary-green-flora-100: #D4F7E6;
  --color-primary-green-flora-200: #A9EFCC;
  --color-primary-green-flora-300: #7EE7B3;
  --color-primary-green-flora-400: #53DF99;
  --color-primary-green-flora-500: #26D07C;
  --color-primary-green-flora-600: #20AC66;
  --color-primary-green-flora-700: #18814D;
  --color-primary-green-flora-800: #105633;
  --color-primary-green-flora-900: #082B1A;

  --color-primary-blue-sky-50: #E6F1FE;
  --color-primary-blue-sky-100: #CEE4FD;
  --color-primary-blue-sky-200: #9DC9FB;
  --color-primary-blue-sky-300: #6BAEFA;
  --color-primary-blue-sky-400: #3A93F8;
  --color-primary-blue-sky-500: #0978F6;
  --color-primary-blue-sky-600: #0762C7;
  --color-primary-blue-sky-700: #054894;
  --color-primary-blue-sky-800: #043062;
  --color-primary-blue-sky-900: #021831;

  --color-gray-0: #FFFFFF;
  --color-gray-50: #F6F9F9;
  --color-gray-100: #E1E6EA;
  --color-gray-200: #D1D9E0;
  --color-gray-300: #C2CCD6;
  --color-gray-400: #A4B3C1;
  --color-gray-500: #8699AC;
  --color-gray-600: #678098;
  --color-gray-700: #536679;
  --color-gray-800: #3E4D5B;
  --color-gray-900: #29333D;
  --color-gray-1000: #151A1E;
  --color-gray-full: #000000;

  --status-color-warnnig-500: #FF9E1B;
  --status-color-success-500: #26D07C;

  --status-color-error-50: #FEF2F2;
  --status-color-error-500: #EF4444;
  --status-color-error-600: #DC2626;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

html, body {
  height: 100%;
}

body {
  box-sizing: border-box;
  line-height: 1.3;
  -webkit-font-smoothing: antialiase;
}

ol, ul {
  list-style: none;
}

a, text-area, button, input {
  font-size: 1rem;
}

button, a {
  cursor: pointer;
  box-sizing: border-box;
  transition: 0.3s ease-in;
}

a {
  text-decoration: none;
  transition: 0.3s ease-in-out;
}

.card {
  position: relative;
  width: 100%;
  height: 600px;
  padding: 1rem 1.25rem;
  background-color: var(--color-gray-0);
  box-shadow: 0px 2px 4px 0px #2e5f911a;
  border-radius: 0px 0px 20px 20px;

  @media(min-width: 568px) {
    padding: 1rem 2rem 0 2rem;
    height: 537px;
  }
}

/*
  * FLAG: BULBE-IFRAME
  * Classes e estilizações do iframe
*/
.card.iframe {
  box-shadow: none;
  border-radius: none;
  height: 537px !important;
  padding: 1.5rem;
}

