@use "sass:math";

$button-variants: (
  primary: (
    border: 1px solid var(--color-primary-blue-universe-700),
    border-radius: 100px,
    background-color: var(--color-primary-blue-universe-700),
    color: var(--color-gray-0),
    font-weight: 600,
    hover: (
      background-color: var(--color-primary-blue-universe-800),
      color: var(--color-gray-0),
    ),
    disabled: (
      background-color: var(--color-gray-300),
      border-color: var(--color-gray-300),
    )
  ),
  secondary: (
    border: 1px solid var(--color-primary-blue-sky-600),
    border-radius: 100px,
    background-color: var(--color-primary-blue-sky-600),
    color: var(--color-gray-0),
    font-weight: 600,
    hover: (
      background-color: var(--color-primary-blue-sky-700),
      color: var(--color-gray-0),
    ),
    disabled: (
      background-color: var(--color-gray-300),
      border-color: var(--color-gray-300),
    )
  ),
  tertiary: (
    border: 1px solid var(--color-primary-green-flora-700),
    border-radius: 100px,
    background-color: var(--color-primary-green-flora-700),
    color: var(--color-gray-0),
    font-weight: 600,
    hover: (
      background-color: var(--color-primary-green-flora-800),
      color: var(--color-gray-0),
    ),
    disabled: (
      background-color: var(--color-gray-300),
      border-color: var(--color-gray-300),
    )
  ),
  outline: (
    border: 1px solid var(--color-gray-300),
    border-radius: 100px,
    background-color: var(--color-gray-0),
    color: var(--color-gray-800),
    font-weight: 400,
    hover: (
      background-color: var(--color-gray-50),
      color: var(--color-gray-800),
    ),
    disabled: (
      color: var(--color-gray-300),
      background-color: var(--color-gray-300),
      border-color: var(--color-gray-300),
    )
  ),
  bulbe: (
    font-weight: 600,
    font-size: 1.125rem,
    border: none,
    background-color: transparent,
    color: var(--color-primary-blue-universe-700),
    responsive: (
      color: var(--color-gray-0),
      background-color: var(--color-primary-blue-universe-700),
      border-radius: 8px,
      padding: 10px 12px,
    ),
    hover: (
    ),
    disabled: (
      color: var(--color-gray-300),
      responsive: (
        color: var(--color-gray-0),
        background-color: var(--color-gray-200),
      )
    ),
  ),
  black-bulbe: (
    font-weight: 600,
    font-size: 1.125rem,
    border: none,
    background-color: transparent,
    responsive: (
      color: var(--color-gray-0),
      background-color: var(--color-gray-full),
      border-radius: 8px,
      padding: 10px 12px,
    ),
    hover: (
    ),
    disabled: (
      color: var(--color-gray-300),
      responsive: (
        color: var(--color-gray-0),
        background-color: var(--color-gray-200),
      )
    ),
  ),
  black-bulbe-rollback: (
    font-weight: 600,
    font-size: 1.125rem,
    border: none,
    background-color: transparent,
    color: var(--color-gray-700),
    hover: (),
    responsive: (
      border-radius: 8px,
      padding: 10px 12px,
      border: 1px solid var(--color-gray-200),
    ),
    disabled: (),
  ),
  "primary-link": (
    border: none,
    border-radius: none,
    background-color: transparent,
    color: var(--color-primary-blue-universe-700),
    font-weight: 400,
    hover: (
      background-color: transparent,
      color: var(--color-primary-blue-sky-600),
    ),
    disabled: (
      color: var(--color-gray-300),
    )
  ),
  "secondary-link": (
    border: none,
    border-radius: none,
    background-color: transparent,
    color: var(--color-primary-yellow-sun-500),
    font-weight: 400,
    hover: (
      background-color: transparent,
      color: var(--color-primary-yellow-sun-500),
    ),
    disabled: (
      color: var(--color-gray-300),
    )
  )
);

$button-size: (
  xs: (
    font-size: 13px,
    padding: 0.563rem 0.75rem,
    gap: 0.5rem,
    img-size: 13px,
    responsive: (
      font-size: 13px,
      padding: 0.563rem 0.75rem,
      gap: 0.5rem,
      img-size: 24px,
    )
  ),
  sm: (
    font-size: 14px,
    padding: 0.594rem 0.75rem,
    gap: 0.5rem,
    img-size: 14px,
    responsive: (
      font-size: 13px,
      padding: 0.563rem 0.75rem,
      gap: 0.5rem,
      img-size: 13px,
    )
  ),
  md: (
    font-size: 16px,
    padding: 0.719rem 0.75rem,
    gap: 0.75rem,
    img-size: 16px,
    responsive: (
      font-size: 14px,
      padding: 0.594rem 0.75rem,
      gap: 0.5rem,
      img-size: 14px,
    )
  ),
  lg: (
    font-size: 18px,
    padding: 1.032rem 1.25rem,
    gap: 0.75rem,
    img-size: 18px,
    responsive: (
      font-size: 16px,
      padding: 0.719rem 0.75rem,
      gap: 0.75rem,
      img-size: 16px,
    )
  ),
  xl: (
    font-size: 24px,
    padding: 1.156rem 1.25rem,
    gap: 0.75rem,
    img-size: 24px,
    responsive: (
      font-size: 18px,
      padding: 1.032rem 1.25rem,
      gap: 0.75rem,
      img-size: 18px,
    )
  ),
);

.btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: 0.3s ease-in;
  line-height: 1;

  cursor: pointer;

  // primary, secondary, tertiary, outline, primary-link, secondary-link
  @each $type, $properties in $button-variants {
    &.#{$type} {
      background-color: map-get($properties, background-color);
      color: map-get($properties, color);
      border: map-get($properties, border);
      border-radius: map-get($properties, border-radius);
      font-weight: map-get($properties, font-weight);

      @if $type == "primary-link" or  $type == "secondary-link"{
        text-decoration: underline;
      }

      @if map-has-key($properties, responsive) {
        @media (max-width: 568px) {
          $responsive: map-get($properties, responsive);
          background-color: map-get($responsive, background-color);
          color: map-get($responsive, color);
          border-radius: map-get($responsive, border-radius);
          padding: map-get($responsive, padding);
          border: map-get($responsive, border);

          span {
            width: 100%;
          }
        }
      }

      &:hover {
        background-color: map-get(map-get($properties, hover), background-color);
        color: map-get(map-get($properties, hover), color);
      }

      &:disabled {
        cursor: not-allowed;
        background-color: map-get(map-get($properties, disabled), background-color);
        border-color: map-get(map-get($properties, disabled), border-color);
        color: map-get(map-get($properties, disabled), color);

        @if map-has-key(map-get($properties, disabled), responsive) {
          @media (max-width: 568px) {
            $disabledResponsive: map-get(map-get($properties, disabled), responsive);
            background-color: map-get($disabledResponsive, background-color);
            color: map-get($disabledResponsive, color);
          }
        }
      }
    }
  }

  // xs, sm, md, lg, xl
  @each $size, $properties-size  in $button-size {
    &.#{$size} {
      font-size: map-get($properties-size, font-size);
      padding: map-get($properties-size, padding);
      gap: map-get($properties-size, gap);

      svg {
        fill: currentColor;
        width: map-get($properties-size, img-size);
      }

      img {
        width: map-get($properties-size, img-size);
      }

      @media(max-width:568px) {
        font-size: map-get(map-get($properties-size, responsive), font-size);
        padding: map-get(map-get($properties-size, responsive), padding);
        gap: map-get(map-get($properties-size, responsive), gap);
        width: 100%;

        svg {
          width: map-get(map-get($properties-size, responsive), img-size);
        }

        img {
          width: map-get(map-get($properties-size, responsive), img-size);
        }
      }
    }
  }
}

.no-responsive-icon {
  @media(max-width: 568px) {
    gap: 0 !important;
  }
}


