mat-checkbox {
  .mdc-checkbox__native-control:enabled~.mdc-checkbox__background {
    border-width: 1px !important;
    border-color: #8699AC !important;
    background-color: #F6F9F9 !important;

  }

  label {
    color: #3E4D5B !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    margin-left: 8px !important;
  }
}

.custom-checkbox {
  //checkbox border and background color
  .mdc-checkbox__native-control:enabled~.mdc-checkbox__background {
    border-width: 1px !important;
    border-color: #8699AC !important;
    background-color: #F6F9F9 !important;
  }

  label {
    font-size: 12px !important;
  }
}

.mdc-checkbox .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background {
  border-color: #7EE7B3 !important;
  background-color: #7EE7B3 !important;
  color: #fff !important;
}

