$input-sizes:(
  sm:(
    padding: 0.5rem 1.25rem,
    font-size: 13px,
    height: 34px,
    responsive: (
      padding: 0.5rem 1.25rem,
      font-size: 0.813rem,
      height: 34px,
    )
  ),
  md:(
    padding: 0.625rem 1.25rem,
    font-size: 1rem,
    height: 40px,
    responsive: (
      height: 34px,
      padding: 0.5rem 1.25rem,
      font-size: 0.813rem,
    )
  ),
  lg:(
    padding: 0.75rem 1.25rem,
    font-size: 1rem,
    height: 46px,
    responsive: (
      padding: 0.625rem 1.25rem,
      font-size: 0.875rem,
      height: 40px,
    )
  ),
  xl:(
    padding: 0.75rem 1.25rem,
    font-size: 1rem,
    height: 49px,
    responsive: (
      padding: 0.75rem 1.25rem,
      font-size: 1rem,
      height: 46px,
    )
  ),
);

.input {
  width: 100%;
  border: 0.5px solid var(--color-gray-400);
  border-radius: 8px;
  font-weight: 400;
  color: var(--color-gray-800);
  background-color: white;

  &::placeholder {
    color: var(--color-gray-400);
  }

  &:focus {
    border-color: var(--color-primary-blue-sky-600);
    background-color: var(--color-primary-blue-sky-50);
    color: var(--color-gray-800);
    outline: none;

    &::placeholder {
      visibility: hidden;
    }
  }

  &:hover {
    border-color: var(--color-primary-blue-sky-600);
  }

  &:disabled {
    cursor: not-allowed;
    border-color: var(--color-gray-400);
    background-color: var(--color-gray-50);
    color: var(--color-gray-400);
  }

  &.error {
    border-color: var(--status-color-error-600);
    background-color: var(--status-color-error-50);
    color: var(--color-gray-800);
  }


  @each $size, $properties in $input-sizes {
    &.#{$size} {
      padding: map-get($properties, padding);
      font-size: map-get($properties, font-size);
      font-weight: map-get($properties, font-weight);
      height: map-get($properties, height);
    }
  }

  @media(max-width:568px) {
    @each $size, $properties in $input-sizes {
      &.#{$size} {
        font-size: map-get(map-get($properties, responsive), font-size);
        padding: map-get(map-get($properties, responsive), padding);
        height: map-get(map-get($properties, responsive), height);
      }
    }
  }
}

label {
  display: block;
  color: var(--color-gray-800);
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 0.25rem;

  @media(min-width:586px) {
    font-size: 14px;
    margin-bottom: 0.4rem;
  }
}

label.disabled {
  color: var( --color-gray-400);
}

.error-message {
  margin-top: 0.25rem !important;
  line-height: 1.5;
  color: var(--status-color-error-600);

  &.sm {
    font-size: 12px;
  }

  &.md {
    font-size: 14px;
  }
}

.prop-required {
  font-size: 12px;
  color: var(--status-color-error-600);

  @media(min-width:586px) {
    font-size: 14px;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid var(--color-gray-400);
  -webkit-text-fill-color: var(--color-gray-800);;
  -webkit-box-shadow: 0 0 0px 1000px var(--color-gray-0) inset;
  transition: background-color 5000s ease-in-out 0s;
}
